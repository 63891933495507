/*
  
  SASS Imports

*/

$frame-size : 10vh;

$breakpoints: (
  "mobile"    "(max-width: 745px)",
  "tablet"    "(min-width: 746px) and (max-width: 1023px)",
  "tablet-up" "(min-width: 746px) and (min-height: 746px)",
  "desktop"   "(min-width: 1024px)",
  "desktop-plus"      "(min-width: 1200px)",
  "wide"      "(min-width: 1400px)",
  "shallow"    "(min-width: 1024px) and (max-height: 850px)",
  "iphone5"   "(min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait)",
  "portrait" "(orientation: portrait)"
) ;

@import './sass/_mixins.scss';
@import './sass/_animations.scss';



@font-face {
    font-family: 'Baskerville';
    src: url('font/Baskerville/NewBaskervilleITCPro-Roman.woff2') format('woff2'),
        url('font/Baskerville/NewBaskervilleITCPro-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baskerville';
    src: url('font/Baskerville/NewBaskervilleITCPro-Italic.woff2') format('woff2'),
        url('font/Baskerville/NewBaskervilleITCPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Untitled';
    src: url('font/Untitled/UntitledSans-Regular.woff2') format('woff2'),
        url('font/Untitled/UntitledSans-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Untitled';
    src: url('font/Untitled/UntitledSans-Medium.woff2') format('woff2'),
        url('font/Untitled/UntitledSans-Medium.woff2') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Untitled';
    src: url('font/Untitled/UntitledSans-RegularItalic.woff2') format('woff2'),
        url('font/Untitled/UntitledSans-RegularItalic.woff2') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

*{
  box-sizing: border-box;
}

%u-text--baskerville{
  font-family:'Baskerville',serif;
  font-weight:normal;

}

%u-text--caps{
  font-family:'Untitled',sans-serif;
  font-weight:bold;
  font-size:0.7rem;
  letter-spacing:0.04em;
  text-transform: uppercase;

}

.List--programme{
  margin-left:-1px;

  li{
    display:inline-block;
    width:50%;
    vertical-align:top;
    padding-left:1px;
    
    @include respond-to('mobile'){
      width:100%;
      padding-bottom:50px;

      &:last-child{
        border-bottom:1px solid #EEE;
      }
    }

  }
}

.Item--programme{
  display: block;
  img{
    width:100%;
    height:auto;
    display:block;
  }

 
  .Item-time{
    @extend %u-text--caps;
    font-weight:200;
  }

  .Item-content,.Item-text{
    padding-top:20px;
  }

  .Item-text{
    p{
      font-size:0.8rem;
      padding-right:50px;
      max-width:300px;
    }
  }
}

.Site-programme{
  padding:20px $frame-size;

  @include respond-to('mobile'){
    padding:20px 30px;
  }

}

.List--ticker{
  white-space:nowrap;
  @extend %u-text--caps;
  padding:0.5em 0;
  margin:0;
  padding-left:1.5em;

  li{
    display:inline-block;
    padding:0 0.2em;
    margin:0;
    &:after{
      content:'•';
      display:inline-block;
      padding:0 0.5em;
    }
  }

  span{
    display:inline-block;
    padding: 0 0.5vmax;
  }
}

.List--nav{

  text-align:center;
  @extend %u-text--caps;
  padding:0.5em 0;
  margin:0;

  li{
    display:inline-block;
    padding:0 2em;
    margin:0;
    width:10em;
    text-align:center;

    &:first-child{
      //padding-left:0
      text-align:right;
    }

     &:last-child{
      //padding-right:0
      text-align:left;
    }

    &:first-child:last-child{
      text-align:center;
    }
    
  }
}


html,body{
	font-family:'Untitled',sans-serif;
	color:#222;
	overflow:hidden;
  background:#fff;
  padding:0;
  margin:0;
  font-size:16px;
}

html, body {
  overscroll-behavior-x: none;
}

h1{

  font-size:1rem;
  font-weight:normal;
  display:inline-block;
  line-height:1.5;
  margin:0;
  padding-left:0px;
  letter-spacing:-0.02em;
  padding:20px;
  padding-left:$frame-size;

  @include respond-to('mobile'){
    padding-left:30px;
  }

}

h2{
  font-size:5vmax;
    padding-left:30px;
    line-height:1;
    font-weight:normal;
    margin-top:0;
    padding-left:50px;
    padding-top:15px;
}

p,h3,h4,h5,h6{
  font-weight:100;
  font-size:1rem;
  padding:0;
  letter-spacing:0em;
  margin:0;
  padding-bottom:5px;

}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

body{
   transition:color 0.3s ease;
}

body.Body--index{

  color:#eee;

  a{
  color:inherit;
  }
  
  &.Body--project{
    color:#222;
  }

}

body.Body--loading{
  height:100vh;
  cursor:wait;
  *{
    cursor:wait;
  }
}

a{
  color:inherit;
  text-decoration:none;
}

a[target="_blank"]{
  cursor:alias;
}

/*

Splash

*/

.Site-head{
  
  /*border-bottom:2px solid #eee;*/
}

.Site-title{
  font-family:'Baskerville',sans-serif;
	position:fixed;
	text-align:center;
	top:40px;
	left:40px;
	font-size:1rem;
	letter-spacing:-0.03em;
	padding:0;
	margin:0;
	font-weight:400;
	z-index:5;


  a{
    text-decoration: none;
    color:#222;
    display:block;
  }
}

.Site-index-heading{
  @extend %u-text--caps;
  padding-left:$frame-size;
  padding-top:20px;
  padding-bottom:15px;

  @include respond-to('mobile'){
    padding-left:30px;
  }

  & + div{
    padding-bottom:$frame-size /2;
  }
}
.Site-index{
  & > div{
    border-top:1px solid rgba(0,0,0,0.3);
    padding:20px;
    padding-left:$frame-size / 2;
    position:relative;
  }

  span{
    font-size:0.8rem;
    position:absolute;
    top:20px;
    left:$frame-size / 2;
  }

  a{
    display:block;
    font-size:5vmax;
    padding-left:$frame-size / 2;
    line-height:1;
  }
}

.Site-nav{
  position:fixed;
  text-align:left;
  bottom:40px;
  left:40px;
  font-size:1rem;
  letter-spacing:-0.03em;
  padding:0;
  margin:0;
  font-weight:400;
  z-index:5;
  list-style:none;
  li{
    padding-top:5px;
     cursor:pointer;
    &:first-child{
      padding-bottom:20px;

    }
  }

  a{
    display:inline-block;
    position:relative;

    .Link-text{
      position:relative;
      z-index:1;
    }
    &:after{
      content:'';
      position:absolute;
      top:0;
      left:0px;
      right:-3px;
      bottom:0;
      display:block;
      pointer-events:none;
      background:#222;
      /*background:rgba(150,150,150,0.7);
      backdrop-filter:blur(10px);*/
      border-radius:15px;
      transform:scale(1.1);
      transition:all 0.4s ease;
      opacity:0;
      z-index:0;
    }
  }
  a.active{

    .Link-text{
      color:#eee;
    }

    &:after{
      opacity:1;
      transform:scale(1.4);
    }
  }

}

.Site-profileLink{
  position:fixed;
  text-align:right;
  top:40px;
  right:40px;
  font-size:1rem;
  letter-spacing:-0.03em;
  padding:0;
  margin:0;
  font-weight:400;
  z-index:5;
  
}

.Site-body{

  padding:40px;
  padding-top:100px;
}


/*

Layers

*/

.Layer{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

.Layer--tiles{
  z-index:0;
}

.Layer--profile{
  z-index:4;
}

.Layer--project{
  z-index:5;
}

/*

Page

*/

.Page,.Page-scroll{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;

}


.Page-ticker{
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:$frame-size;

  & > *{
    position:absolute;
    width:100%;
    top:50%;
    transform: translateY(-50%);
  }
}
.Page-scroll{
  overflow-y:auto;
  z-index:1;
}

.is-touch .Page-scroll{
   overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }


.Page-horiz-scroll{
  padding-top:20px;
  padding-bottom:100px;
  padding-left:250px;
  white-space: nowrap;
  overflow-x:auto;
  transform: translateY(-20px);
}

.Page-thumb{
  display:inline-block;
  padding-right:40px;
  white-space:normal;
  position:relative;

  a{
    display:block;
    opacity:0.9;
    transition:opacity 0.4s ease;

    &:hover{
      opacity:1;
    }
  }

  img{
    display:block;
    height:150px;
    width:auto;
    border-radius:10px;
    margin-bottom:20px;
    box-shadow:0 0 10px rgba(0,0,0,0.2);
  }
}

.Page--project{

 

 

  .Page-paper{
    background:white;
    
    box-shadow:0 20px 50px rgba(0,0,0,0.2), 0 50px 100px rgba(0,0,0,0.5);
    min-height:100vh;

    *{
      //color:white;
    }
    
  }

  .Page-blocks{
    padding-bottom:100px;
  }

  .Page-blackout{
    height:100vh;
    background:black;

  }

  .Page-tiles{
    margin-left:-10px;
    text-align:center;

    li{
      display:inline-block;
      width:25%;
      padding-left:10px;
      vertical-align:top;
      padding-bottom:10px;
    }
  }

  .Page-blocks--tiles{
    padding:0 0px;
    min-height:100vh;
  }

  .Page-tile{
    padding-top:100%;
    position:relative;
    & > *{
      display:block;
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      width:100%;
      height:100%;
      object-fit:contain;
    }
  }
.Page-audio{
  display:block;
  margin-left:50px;
  padding-left:0px;
  margin-right:50px;
  max-width:500px;
  padding-bottom:50px;
  padding-top:0px;
  position:relative;
  //border-top:1px solid;
  //margin-bottom:50px;
  z-index:2;

  &:before{
    content:'Hear more:';
    display:block;
    position:absolute;
    top:0;
    //left:50px;
    transform: translateY(-100%);
    font-size:1rem;
    display:none;
  }
  audio{
    display:block;
    width:100%;
    max-width:500px;
    margin-left:-5px;

  }
}
  .Page-media{
    position:absolute;
    display:block;
    width:80%;
    height:80%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    object-fit:contain;
    z-index:1;
    //filter:grayscale(100%);
    opacity:0.2;
    background-size:contain;
    background-position:center center;
    background-repeat:no-repeat;
    margin:0 auto;

    img{
      display:block;
      width:100%;
    }
  }

  .Page-close{
    position:absolute;
    top:$frame-size /2;
    left:$frame-size /2;

    @include respond-to('mobile'){
      left:auto;
      right:0px;
    }

   
    transform:translate(-50%,-50%);

    z-index:2;
    
    width:30px;
    height:30px;
    border-radius:20px;
    background:rgba(50,50,50,0.2);
    backdrop-filter:blur(5px);
    transition: transform 0.4s ease;
    

    svg{
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      
      width:20px;
      height:20px;
      path{
        stroke:#000;
      }
    }
  }

  .Page-body{
    padding-bottom:$frame-size;
  }

   .Page-content{
    padding: $frame-size;
    padding-bottom:50px;
    padding-top:0;
    position:relative;
    z-index:2;

    @include respond-to('mobile'){
      padding:30px;
    }


    

    p{
      padding-bottom:1em;
      line-height:1.4;
      max-width:35rem;
      margin-left:auto;
      margin-right:auto;
    }

     p:first-child{
      font-size:3vmax;
      max-width:none;
      line-height:1.3
    }

    h4{
      padding-bottom:1em;
      line-height:1.4;
      max-width:35rem;
      margin-left:auto;
      margin-right:auto;
      color:#eb9662;
      font-size:1.5em;
 
    }

    h3{
      @extend %u-text--caps;
      padding-bottom:25px;
      @include respond-to('mobile'){
        width:70%;
      }
    }

    h5,h6{
      @extend %u-text--baskerville;
      font-size:2.5vmax;
      @include respond-to('mobile'){
        font-size:1.5rem;
        margin:1em 0;
      }
      max-width:20em;
     transform: translateX(4em);
     
      position: relative;
      padding-right:3em;
      line-height:110%;
      margin:1.5em 0;

      @include respond-to('mobile'){
        transform:none;
        padding-right:1em;
      }
    }

    h5{
      &:before{
        content:'“';
        position:absolute;
        top:0;
        left:0;
        transform:translateX(-110%);
      }
      &:after{
        content:'”'
      }
    }


    h3:first-child + p{
      @extend %u-text--baskerville;
      font-size:2vmax;

      @include respond-to('mobile'){
        font-size:3vmax;
      }
    }
  }

   .Page-basic-content{
    padding: 50px;
    padding-top:0;
    position:relative;
    z-index:2;


     p:first-child{
      font-size:3vmax;
    }

    p{
      padding-bottom:1em;
    }
  }

  .Page-detail{
    padding: 50px;
    padding-right:0;
    padding-left:$frame-size;
    padding-bottom:$frame-size;
    padding-top:0;
    position:sticky;
    top:50px;

    @include respond-to('mobile'){
      padding:30px;
      top:auto;
      position: relative;
    }

    p{
      font-size:0.8rem;
      max-width:20em;
    }
  }

  @include respond-to('desktop'){
    .Page-content,.Page-detail{
      display:inline-block;
      width:40%;
      vertical-align: top;
    }
    .Page-content{
      width:60%;
      padding-right:0;
      p:first-child{
        font-size:2vmax;
      }
    }

    .Page-detail{
      max-width:400px;
    }
  }

  .Content{
    p{
      padding-bottom:1em;
    }

    a{
      border-bottom:1px solid;
      transition:border 0.4s ease;
    }

    a:hover{
      border-color:transparent;
    }

    h3{
      @extend %u-text--caps;
      padding-bottom:2rem;
    }

    dl{
      margin:0;
      padding-bottom:1rem;

      dt{
        @extend %u-text--caps;
        font-size:0.7rem;
        font-weight:200;
        display:inline-block;
        width:33%;
        vertical-align:top;
        padding:0;
        margin:0;
      }

      dd{
        font-size:0.8rem;
        display:inline-block;
        width:66%;
        vertical-align: top;
        padding:0;
        margin:0;
      }
      li{
        margin:0;
        padding-bottom:1rem;
        position:relative;
        max-width:400px;
      }

      p{
        font-size:0.8rem;
      }
    }
  }
  

}

.Page--profile,
.Page--index,
.Page--home{

  background:#eee;

  &.Page--home{
    background:white;
  }

  .Page-title{
    display:none;
  }
  .Page-paper{
      padding-left:250px;
      padding-top:40px;

      padding-bottom:20px;

      padding-right:100px;
      max-width:1100px;

      opacity:0;
      animation:scaleIn 0.8s 0.2s ease forwards;
      transform-origin:50% 0;

      p{
        margin-top:0;
        padding-top:0;
      }
  }


}

.Page--index{

  background:#222;
  color:#eee;

  font-size:80px;
  font-weight:200;

  .Page-bg{
    opacity:0.5;
    background:black;
    & > *{
      position:absolute;
      width:100%;
      height:100%;
      object-fit:cover;
    }
  }

  

  a{
    text-decoration: none;
    color:#fff;
    transition:all 1s ease;
    opacity:0.5;
    /*border-bottom:2px solid transparent;*/
    display:inline-block;
    position:relative;
  }

  a:hover{
    /*border-bottom:2px solid;*/
    opacity:1;
    transition:all 0.7s ease;
  }
}

.Page-belowFold{


  border-top:1px solid #EEE;
  padding-top:$frame-size / 2;

}

.Page-aboveFold{

  padding-top:$frame-size / 2;
   padding-bottom:$frame-size / 2;

   .Page-detail{
    padding-bottom:0;
   }

   .Page-body{
    padding-bottom:0;
   }

}

.Page-logo{
  padding-top:$frame-size / 2;
  padding-bottom:$frame-size / 2;
  padding-left:$frame-size;

  border-bottom:1px solid #EEE;
  margin-bottom:$frame-size / 2;

  @include respond-to('mobile'){
    padding-left:30px;
  }


  img{
    width:40%;
    max-width:150px;
  }
}

.Layer--project{
  .Page-belowFold{
      min-height:100vh;
  }
}

/*

Home

*/

.Page--home{

 .Page-profile{
  background:#eee;
  min-height:100vh;
 }
  .Page-reel{
    height:100vh;

  }
  .Page-reel-media{

    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    pointer-events:none;
    z-index:-1;
    background:black;
  }

  .Page-reel-skip{
    position:absolute;
    bottom:30px;
    left:50%;
    color:white;
    mix-blend-mode:difference;
    svg{

      width:20px;
      height:20px;
      path{
        stroke:#EEE;
      }
    }
  }

  .Page-sections{
    background:white;
   
        box-shadow:0 -20px 50px rgba(0,0,0,0.2), 0 -50px 100px rgba(0,0,0,0.5);
  }
}

/*

Profile

*/

.Profile p{
  margin-top:0;
  padding-top:0;
  font-size:1rem;
}

.Profile-intro p{
  font-size:1.4rem;
 margin-right:0;
 padding-bottom:20px;
 color:#222;
 font-weight:300;
}

.Profile a{
  color:#111;
  opacity:0.5;
  transition:0.4s ease;
}

.Profile a:hover{
  opacity:1;
}
.Profile ul{
  padding-bottom:20px;
}

.Profile li{
  padding-bottom:0.4em;
}

.Profile-aside{
  padding:40px 0;
  display:flex;
  /*border-top:1px solid rgba(0,0,0,0.1);
  margin-top:40px;*/
  width:75%;
  opacity:0.7;
}

.Profile-aside > div{
  padding-right:40px;
}

/*

Blocks

*/

.Blocks--inset{
  padding-left:40px;
  padding-right:40px;
}

.Blocks-grid{
  margin-left:-40px;
}

.Block{
  display:inline-block;
  vertical-align:top;
  width:100%;
  padding-left:40px;
  padding-bottom:40px;
}

.Block--media{
  img,video{
    display:block;
    width:100%;
  }
}

.Block--size-inset{
  padding-left:290px;
}

.Block--size-half-grid{
  width:50%;
}

.Block-caption{
  padding:20px 0;
}

.Block--size-full .Block-caption{
  padding-left:40px;
}

.Block--size-inset{
  position:relative;
}
.Block--size-inset .Block-caption{
  position:absolute;
  top:0;
  left:40px;
  bottom:40px;
  width:250px;
  padding:0px;
  box-sizing:border-box;

  span{
    display:block;
    position:sticky;
    top:50%;
    left:0;
    padding:40px;
    padding-right:60px;
    padding-top:0;
    padding-bottom:0;
  }
}

/*

Burger

*/

.Burger{

  position:fixed;
  top:35px;
  right:40px;
  background:rgba(150,150,150,0.7);
  backdrop-filter:blur(10px);

  z-index:10;
  padding:5px;
  border-radius:20px;
  display:flex;
  width:50px;
  height:30px;
  justify-content:space-evenly;
  align-items:center;
  transition:all 0.4s ease;
  transform: translate3d(0,0,0);

  svg{
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      
      width:20px;
      height:20px;
      path{
        stroke:#EEE;
      }
    }
}

.Burger-text{
  display:none;
}

.Burger:hover{
  background:#212121;
  color:#fff;
}

@keyframes bounce{

  0%{
    transform:translateY(0) scaleY(1);
    background:#ddd;

  }

  10%{
    transform:translateY(80%) scaleY(0.8);
    background:#fff;
  }

  20%{
    transform:translateY(0%) scaleY(1);
    background:#ddd;
  }

}

.Burger-el{
  width:5px;
  height:5px;
  border-radius:4px;
  background:#ddd;
  display:block;
  animation:bounce 3s ease infinite;
}

.Burger-el--2{
  animation-delay:0.1s;
}

.Burger-el--3{
  animation-delay:0.3s;
}

/*

DragWall

*/


[data-draggable]{
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;

}

.DragWall{
  top:0;
  left:0;
  bottom:0;
  right:0;
  background:transparent;
  position:fixed;
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  //cursor: url("https://github.com/chenglou/react-motion/raw/master/demos/demo8-draggable-list/cursor.png") 39 39, auto;
  cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    transform:scale3d(0.99,0.99,1);
    opacity:0;
    //will-change: opacity,transform;
  
  &.DragWall--visible{
    opacity:0;
    animation:scaleIn 0.8s 0.2s ease forwards;
  }

  &.DragWall--hidden{
      opacity:1;
      animation:fadeOut 0.4s 0s ease forwards;
  }

  &:after{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    //background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 100%);
    content:'';
    pointer-events:none;
    opacity:0.5;
  }


}

/*

Tile

*/

.Tile{
	
	height:100%;
	width:100%;
	position:relative;
	padding:0 0;
	opacity:0;
	animation:fadeIn 0.8s 0.2s ease forwards;
  transform:translate(-20%,-20%);
  &.Tile--noTransition{
    animation:none;
    opacity:1;
  }

	display:block;

  & > div{
    width:100%;
  }

  .Tile-link{
    display:block;
    pointer-events:none;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    background:#222;
    width:40px;
    height:40px;
    border-radius:40px;
    color:#FFF;
    transition:opacity 0.6s ease;
    opacity:0;
    svg{
      width:50%;
      height:50%;
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      path{
      stroke:#fff;
      }
    }
  }

  &:hover{
    .Tile-link{
      opacity:1;
      pointer-events:all;
    }
  }

  .Tile-image{

    width:100%;
    height:100%;
    position:absolute;
    
    overflow:hidden;
    pointer-events:none;

    & > *{
      position:absolute;
      top:50%;
      left:50%;
      width:100%;
      height:100%;
      object-fit:contain;
      transform: translate(-50%,-50%);
    }

    &.Tile-image--hidden{
      opacity:0;
    }
  }

  a{
    display:block;
  }
	img{
		//width:100%;

		//border-radius:5px;
		//display:block;
		//margin:20px auto;
		//box-shadow:0 0 50px rgba(0,0,0,0.2);
	}

  .FocusImage{
    /*transition:all 0.8s cubic-bezier(0.33, 1, 0.68, 1);;
    width:50%;
    margin:0 auto;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    &.FocusImage--active{
      
      width:100%;
      box-shadow: 0 10px 50px rgba(0,0,0,0.2),0 20px 100px rgba(0,0,0,0.2);

    }*/
    position:absolute;
    width:75%;
    height:75%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    overflow:hidden;
  }

}

.Highlighter{
  .FocusImage{
    //transform:translate3d(-50%,-50%,0);
    img{
      
    }
  }
}

/*

Swiper

*/

.swiper-container{


  .swiper-slide img {
    display: block;
    width: 100%;
    height:100%;
    object-fit: cover;
  }

  .swiper-pagination{
    display:none;
  }
  .swiper-button-prev,.swiper-button-next{
   
    height:100%;
    top:0;
    width:50%;
    &:after{
      display:none;
    }
  }
}

/*

Tooltip

*/

.Tooltip{
  position:fixed;
  pointer-events:none;
  z-index:100;
  transition:opacity 0.2s ease;
  opacity:1;
  width:20vw;
  height:20vw;
  overflow:hidden;
  transform: translateY(-50%);
  z-index:-1;
  *{
    width:100%;
    height:100%;
    object-fit:cover;
  }
  

}

.Tooltip--cursor{
  position:fixed;
  pointer-events:none;
  z-index:100;
  transition:opacity 0.2s ease;
  opacity:0;
  width:40px;
  height:40px;
  overflow:hidden;
  transform: translate(-50%,-50%);
  z-index:999;
  background:rgba(0,0,0,0.15);
  backdrop-filter:blur(5px);
  border-radius:20px;
  *{
    width:50%;
    height:50%;
    left:25%;
    top:25%;
    position:absolute;
    object-fit:contain;

    path{
      stroke:white;
    }
  }
  

}

.Body--cursor-active{
  cursor:none;
  *{
    cursor:none;
  }
  .Tooltip--cursor{
    opacity:1;
  }
}

.Body--cursor-state-prev{

  .Tooltip{
    transform: translate(-50%,-50%) rotate(-180deg);
  }
}


.Intro{
  position:relative;
  height:100vh;
 overflow:hidden;
  width:100%;
  //pointer-events:none;
}

.Intro-title{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  z-index:2;
  font-family:'Baskerville',sans-serif;
  font-size:8vw;
  width:65%;
  text-align:left;
  line-height:1;
  color:white;
  font-weight:normal;
  letter-spacing:0.01em;
  padding:0;
  margin:0;
  z-index:999;
  pointer-events:none;
  text-shadow:0 0 20px rgba(0, 0, 0 ,0.5);

  img{
    width:50%;
    max-width: 400px;
  }

  p{
    font-family:'Untitled',sans-serif;
    font-size:5.5vmax;
    max-width:70vmax;
    line-height:100%;

    *{
      line-height: 100%;
    }

    em{
      font-family:'Baskerville',serif;
    }
  }

  .Intro-author{
    font-size:2.3vmax;
    padding-top:1em;
    letter-spacing:0;
    text-align:center;
    display:block;
    font-style:italic;
  }
}

.Intro-head{
  position:absolute;
  top:0;
  left:0;
  right:0;
  z-index:2;
  height:$frame-size;

  & > *{
    position:absolute;
    top:50%;
    left:0;
    right:0;
    transform: translateY(-50%);
  }
}

.Intro-foot{
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  z-index:2;
  height:$frame-size;

  & > *{
    position:absolute;
    top:50%;
    left:0;
    width:100%;
    transform: translateY(-50%);
  }
}

.Intro-ticker1{
  position:absolute;
  top:0;
  left:0;
  width:100vh;
  background:red;
  transform-origin:0% 0%;
  transform: rotate(90deg) translateY(-100%);
  z-index:2;
  height:$frame-size;

  & > *{
   /* position:absolute;
    top:50%;
    left:0;
    width:100%;
    transform: translateY(-50%);*/
  }
}

.Intro-gallery{
  position:absolute;
  z-index:1;
  height:100%;
  width:100%;
}

.Intro-credit{
  position:absolute;
 bottom:10%;
  left:$frame-size;
  right:$frame-size;
  padding:2rem;
  p{
    font-size:1rem;

    @include respond-to('mobile'){
      font-size:0.8rem;
    }
  }
  color:white;
  z-index:2;
  @include respond-to('mobile'){
    left:30px;
    right:30px;
 
  }

}

.Intro-media{
  position:absolute;
  top:10%;
  left:$frame-size;
  right:$frame-size;
  bottom:10%;
  z-index:1;
  background:#B99763;
  @include respond-to('mobile'){
    left:30px;
    right:30px;
  }

  @include respond-to('portrait'){
    /*top:30%;
    bottom:30%;*/

    
  }

  & > *{
    position:absolute;
    height:100%;
    width:100%;
    object-fit:cover;
  }
}

.Intro-gallery > div:first-child{
    .Intro-media{
      & > *{
        object-fit:cover;
      }
    }
  }
.Intro-gallery > div:nth-child(even){
    .Intro-media{
      top:20%;
      bottom:20%;
      left:15%;
      right:15%;
    }
  }

  .Intro-gallery > div:nth-child(4){
    .Intro-media{
      top:10%;
      bottom:10%;
      left:15%;
      right:15%;
    }
  }



.Site-title{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  z-index:2;
  font-family:'Baskerville',sans-serif;
  font-size:8vw;
  width:100%;
  text-align:center;
  line-height:1;
  color:white;
  font-weight:normal;
  letter-spacing:0.01em;
  padding:0;
  margin:0;
  z-index:999;
  pointer-events:none;
}

/*

Slice 

*/
.Slice{
  height:100vh;
  position:relative;
}

.Slice--window{
  height:100vh;
  position:relative;
}
.Slice--vimeo{
  position:absolute;
  width:100vw;
  height:100vh;
  clip: rect(0, auto, auto, 0);

  .Slice-media{
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    pointer-events:none;
    z-index:0;

    .Slice-media-frame{
      width:100%;
      height:100%;
      &:before{
        content:'';
        display:block;
        padding-top:9/16 * 100%;
      }
      background:black;
      position:absolute;
      object-fit:cover;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);

      iframe{
        display:block;
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
      }
    }

    
  }
}
.Slice--carousel{
    background:white;
    position:relative;
    z-index:2;
    .Slice-body{
      width:195px;
      position:sticky;
      top:35%;
      left:0;
      padding:40px;
      padding-bottom:90px;
      padding-right:10px;
    }

    .Slice-icons{
      width:195px;
      position:sticky;
      top:calc(100% - 90px);
      left:0;
      padding:40px;
      padding-right:10px;
      z-index:10;

      svg{
        width:24px;
        //height:18px;
      }

      & > div.Slice-icon{
        display:inline-block;
        padding-right:5px;
        position:relative;
      }
      
      a{
        opacity:0.5;
        transition:opacity 0.5s ease;
        display:inline-block;
        position:relative;
      }
      

      a span{
        display:inline-block;
        position:absolute;
        bottom:100%;
        margin-bottom:10px;
        left:50%;
        transform:translate(-50%,20%);
        background:rgba(0,0,0,0.2);
        padding:5px 10px;
        border-radius:20px;
        font-size:10px;
        white-space:nowrap;
        opacity:0;
        transition:all 0.4s ease;

      }

      a:hover{
        opacity:1;

        span{
           transform:translate(-50%,0%);
           opacity:1;
        }
      }
    }

    .Slice-scrub{
      display:block;
      width:calc(100vw - 280px);
      top:0;
      left:100%;
      margin-left:30px;
      position:absolute;
      z-index:10;
      background:rgba(180,180,180,0.7);
      backdrop-filter:blur(10px);
      padding:8px 10px;
      border-radius:10px;
      white-space: nowrap;
      overflow:hidden;
      line-height:0;
      transition:all 0.4s ease;

      opacity:0;
      pointer-events:none;
      transform: translateY(15px);

      &.active{
        opacity:1;
        transform: none;
        pointer-events:all;
      }

      .Slice-scrub-track{
        position:relative;
      }
      .Slice-scrub-thumb{
        height:60px;
        line-height:0;
        display:inline-block;
        width:100px;
       background-color:black;
        background-position:left center;
         background-size:auto 100%;
        background-repeat:repeat-x;
        opacity:0.5;

        transition:opacity 0.6s ease;
        &:hover{
          opacity:1;
        }
        
        &:first-child{
          border-top-left-radius:5px;
          border-bottom-left-radius:5px;
        }
        &:last-child{
          border-top-right-radius:5px;
          border-bottom-right-radius:5px;
        }
      }
    }

    .Slice-media{

      .Slice-media-foot{
  
        position:absolute;
        bottom:30px;
        left:40px;
        right:40px;
        height:60px;
        z-index:11;
        top:auto;
      }

      position:absolute;
      top:0px;
      left:210px;
      right:40px;
      bottom:0;
      z-index:8;

       & > *{
          position:absolute;
          top:0;
          right:0;
          bottom:0;
          left:0;
        }

      .Slice-object{
         position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;

        & > *{
          position:absolute;
          top:0;
          right:0;
          bottom:0;
          left:0;
          object-fit: cover;
        }

        &.Slice-object--contain{
          & > *{
            object-fit: contain;
          }
        }
      }

      .Swiper-next,
      .Swiper-prev{
        position:absolute;
        top:0;
        height:100%;
        width:50%;
        left:0;

        z-index:10;
      }


      .Swiper-next{
        right:0;
        left:auto;
      }

      &.Slice-media--contain{

        .swiper-slide img {
          object-fit: contain;
        }

      }
    }



}

.Footer{
  background:black;
  padding:2rem;
  color:white;

  svg{
    @include respond-to('mobile'){
      max-width:100px;
      height:auto;
    }
  }
}

.Footer-body{
  display:flex;
  align-items:center;
  justify-content:space-between;
}

.Footer-credit{
  display:flex;
  gap:0.5em;
}