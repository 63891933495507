@keyframes scaleIn{
  0%{
    transform:scale3d(0.99,0.99,1);
    opacity:0;
  }
   100%{
    transform:scale3d(1,1,1);
    opacity:1;
  }
}
@keyframes scaleOut{
  0%{
    transform:scale3d(1,1,1);
    opacity:1;
  }
  100%{
    transform:scale3d(0.99,0.99,1);
    opacity:0;
  }
   
}
@keyframes fadeUp{
  0%{
    transform:translate(0,5px);
    opacity:0;
  }
   100%{
    transform:translate(0,0);
    opacity:1;
  }
}
@keyframes fadeIn{
  0%{
    opacity:0;
  }
   100%{
    opacity:1;
  }
}
@keyframes fadeOut{
  0%{
    opacity:1;
  }
   100%{
    opacity:0;
  }
}
@keyframes fadeInOut{
  0%{
    opacity:0;
  }
  20%{
    opacity:1;
  }
  80%{
    opacity:1;
  }
   100%{
    opacity:0;
  }
}

@keyframes fadeInOutPulse{
  0%{
    opacity:0;
  }
  50%{
    opacity:1;
  }
   100%{
    opacity:0;
  }
}