// 2x cursor

@mixin responsive-cursor($src,$w,$h) {
  cursor: url(#{$src}.png) $w $h, pointer; /* Legacy */
    /*cursor: url("/ui/img/cursor.svg") 50 50, pointer;*/
    cursor: -webkit-image-set(url(#{$src}.png) 1x, url(#{$src}@2x.png) 2x) $w/2 $h/2, pointer; /* Webkit */
    cursor: image-set(url(#{$src}.png) 1x, url(#{$src}@2x.png) 2x) $w/2 $h/2, pointer; /* Webkit */

}

@mixin respond-to($mq) {
  $breakpoint-found: false;

  // Loop through the list of breakpoints in our settings file
  @each $breakpoint in $breakpoints {
    // Grab the alias and the condition from their respective locations in the list
    $alias: nth($breakpoint, 1);
    $condition: nth($breakpoint, 2);

    // If the media query we’ve specified has an alias and a condition
    @if $mq == $alias and $condition {
      // tell the mixin that we’ve found it
      $breakpoint-found: true;

      // and spit it out here
      @media #{$condition} {
        @content;
      }
    }
  }

  // If the alias doesn't exist
  @if $breakpoint-found == false {
    @warn "Oops! Breakpoint '#{$mq}' does not exist.";
  }
}


